// Colors
$white: #FFFFFF;
$bone-white: #F5F5F5;
$background-gray: #F2F2F2;
$light-gray: #9D9898;
$gray: #808080;
$dark-gray: #404040;
$red: #DC3545;
$green: #28A745;
$yellow: #FFC107;
