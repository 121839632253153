@import '../../styles/variables';

.setting-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;

  &__name {
    width: 155px;
    color: $bone-white;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }

  &__control {
    flex-grow: 1;
    text-align: center;
    margin: 0 15px;

    & > input[type=number] {
      all: unset;
      background: $bone-white;
      width: 100%;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;

    &__help {
      background: $bone-white;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      font-size: 13px;

      &::before {
        content: '?';
      }
    }
  }
}
