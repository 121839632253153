@import '../../styles/variables';
@import '../../styles/mixins';

.interface-screen {
  min-width: 345px;
  width: 100%;
  min-height: 100%;
  height: fit-content;

  @media screen and (min-width: 435px) {
    min-height: fit-content;
    width: 435px;
    height: 800px;
  }

  &-game {
    height: 100%;
    width: calc(100vh * 9 / 16);

    & > div > div {
      margin: 0;
    }
  }

  display: flex;
  flex-direction: column;

  &__container {
    height: 100%;
    width: 100%;
    flex-grow: 1;
    background: $light-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 30px;
      background: $dark-gray;
      flex-grow: 1;
      align-self: stretch;

      &__header {
        padding: 0 20px;
        height: 50px;
        margin: 5px 5px 0;
        background: $gray;

        &__title {
          user-select: none;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          background: $light-gray;
          color: $white;
          font-size: 28px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      &__game {
        height: 100%;
        position: unset;
        opacity: 1;
        display: flex;
        flex-grow: 1;
        outline: none;

        &:focus-visible {
          outline: none;
        }

        &_hidden {
          height: unset;
          position: absolute;
          opacity: 0;
          z-index: -1;
        }

        &__canvas-field {
          min-width: 348.75px;
          min-height: 620px;
          max-height: calc(100vw * 16 / 9);
          height: 100%;
          width: 100%;
          margin: auto 0;
          outline: none;

          &:focus-visible {
            outline: none;
          }

          & > canvas {
            min-width: 348.75px;
            min-height: 620px;
            outline: none;
            user-select: none;

            &:focus-visible {
              outline: none;
            }
          }

        }
      }
    }
  }
}
