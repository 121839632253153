@import '../../styles/variables';
@import '../../styles/mixins';

.fullscreen-button {
  @include controls-panel-button;
  background: url('../../../public/images/expand.png') no-repeat center $white;
  background-size: 60% 60%;

  &-compress {
    background: url('../../../public/images/compress.png') no-repeat center $white;
    background-size: 60% 60%;
  }
}
