@import "variables";

@mixin disable-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
}

@mixin position-absolute-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin controls-panel-button {
  cursor: pointer;
  padding: 0;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 10px;

  &:hover {
    background-color: $bone-white;
  }
}

@mixin close-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: url('../../public/images/close.png') no-repeat right $gray;
  align-self: flex-end;
  background-size: 70%;
}

