@import '../../styles/variables';

.modal-window {

  &__backdrop, &__content {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__backdrop {
    width: 100%;
    height: 100%;
    z-index: 2;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.6);

    &-opaque {
      background-color: rgba(64, 64, 64, 1);
    }
  }

  &__content {
    z-index: 3;
    outline: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    min-width: 330px;
    width: 375px;
    height: fit-content;
  }
}
